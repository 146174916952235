<template>
  <div>
    <div
      class="filter-panel"
      v-if="
        [DEPARTMENT_TYPES.DIRECTOR, DEPARTMENT_TYPES.MANAGER].includes(
          Number(staffInfo.dutyType)
        )
      "
    >
      <CSSelect style="margin-right: 20px">
        <select v-model="queryParams.dutyType" @change="changeDepartment">
          <option value="">全部部门</option>
          <option
            v-for="department in departmentList"
            :key="department.code"
            :value="department.dutyType"
          >
            {{ department.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 30px">
        <select v-model="queryParams.createUser">
          <option value="">全部创建人</option>
          <option v-for="staff in staffList" :key="staff.id" :value="staff.id">
            {{ staff.name }}
          </option>
        </select>
      </CSSelect>
      <button class="btn btn-search btn-primary" @click="getScheduleList()">
        查询
      </button>
    </div>

    <div
      class="result-panel"
      :style="{
        'margin-top': [
          DEPARTMENT_TYPES.DIRECTOR,
          DEPARTMENT_TYPES.MANAGER,
        ].includes(Number(staffInfo.dutyType))
          ? '20px'
          : '0',
      }"
    >
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>创建时间</th>
            <th>班表名称</th>
            <th>员工</th>
            <th>所属部门</th>
            <th>创建人</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="scheduleList && scheduleList.length > 0">
            <tr v-for="schedule in scheduleList" :key="schedule.id">
              <td>{{ schedule.createTime }}</td>
              <td>
                <span
                  class="allow-click"
                  @click="toAttendanceScheduleDetail(schedule.id)"
                >
                  {{ schedule.name }}
                </span>
              </td>
              <td>
                <span v-if="schedule.count > 0">
                  {{ schedule.count }}
                </span>
                <span v-else> -- </span>
              </td>
              <td>{{ DEPARTMENT_TYPE_STR[schedule.duty_type] }}</td>
              <td>{{ schedule.userName }}</td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination />
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import { queryRosterUrl } from "@/requestUrl";
import { DEPARTMENT_TYPE_STR, DEPARTMENT_TYPES, ORG_LEVEL } from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  name: "AttendanceSchedule",
  components: {
    CSTable,
    CSSelect,
    Pagination,
  },
  data() {
    return {
      DEPARTMENT_TYPES,
      staffInfo: this.$vc.getCurrentStaffInfo(),
      DEPARTMENT_TYPE_STR,
      filterHeight: 0,
      queryParams: {
        dutyType: "",
        createUser: "",
      },
      departmentList: [],
      staffList: [],
      scheduleList: [],
    };
  },
  async mounted() {
    window.addEventListener("keydown", this.getScheduleListDown);

    if (
      [DEPARTMENT_TYPES.DIRECTOR, DEPARTMENT_TYPES.MANAGER].includes(
        Number(this.staffInfo.dutyType)
      )
    ) {
      this.departmentList = await this.$vc.getDepartmentList({
        level: ORG_LEVEL.DEPARTMENT,
        parentCode: this.staffInfo.companyCode,
      });
      this.staffList = await this.$vc.getStaffList({
        isOneTheJob: true,
        departmentCode: this.staffInfo.companyCode,
      });
    } /* else {
      this.queryParams.dutyType = this.staffInfo.dutyType;
    }*/
    this.getScheduleList();
    this.$vc.on(this.$route.path, "pagination_page", "event", (page) => {
      this.getScheduleList(page);
    });
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getScheduleListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getScheduleListDown);
  },
  methods: {
    getScheduleListDown(e) {
      if (e.keyCode == 13) {
        this.getScheduleList();
      }
    },
    /**
     * 跳转到排班详情页面
     * @param {String | Number} id 排班id
     * */
    toAttendanceScheduleDetail(id) {
      this.$router.push({ name: "attendanceScheduleDetail", params: { id } });
    },
    async changeDepartment(e) {
      console.log("查看e", e);
      this.staffList = await this.$vc.getStaffList({
        isOneTheJob: true,
        departmentCode: this.departmentList[e.target.selectedIndex - 1].code,
      });
    },
    // 获取考勤排班
    getScheduleList(curPage = 1, pageSize = 10) {
      console.log(this.$vc.getCurrentStaffInfo().id);
      const params = { ...this.queryParams, curPage, pageSize };
      this.$fly
        .post(queryRosterUrl, this.$vc.removeNullProperty(params), {
          headers: {
            "x-operator-id": this.$vc.getCurrentStaffInfo().id,
          },
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          if (curPage === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: curPage,
              pageSize,
            });
          }
          this.scheduleList = res.data.datas;
        });
    },
  },
};
</script>

<style scoped lang="stylus"></style>
